@use "/src/components/styles/colors";

.menu {
  & a {
    font-weight: 600;
    color: colors.$black;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    padding-bottom: 10px;
    padding-left: 20px;
    position: relative;
    text-align: center;
    @media screen and (min-width: 768px) {
      color: colors.$white;
      padding: 0 10px;
    }

    &:hover {
      color: colors.$msb-light;
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: colors.$white;
      transform-origin: bottom right;
      transition: transform 0.2s ease-out;
    }
    &:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
      height: 1px;
    }
  }
}
