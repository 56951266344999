@media screen and (max-width: 768px) {
  header {
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.75)
      ),
      url(../../../assets/img/MSB-BG.jpg);
    background-size: cover;
  }
}

@media screen and (min-width: 768px) {
  header {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.75),
        rgba(255, 255, 255, 1)
      ),
      url(../../../assets/img/MSB-BG.jpg);
    background-size: cover;
  }
}
